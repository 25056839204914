<template>
  <div v-if="Object.values(gameTypes).length > 0" class="w-full flex-wrap flex justify-start">
    <div class="pt-1 w-1/3" :class="{'px-2':index === 1 || index === 4,'pr-2':index === 2 }" v-for="(category, index) in categories" :key="index">
      <label class="font-semibold text-brand mt-2">{{selectBoxPlaceholder(index)}}</label>
      <div class="w-full mt-1">
        <select @change="updateCategories($event, index)" class="p-1 border-solid border-brand-light border w-full rounded">
          <option value="noSelected" class="bg-brand rounded text-white hover:bg-brand-dark">--Не выбрано--</option>
          <option v-for="(type, key) in category" :selected="selectedKeys[index] === key" :key=key :value="key"
                  class="bg-brand rounded text-white hover:bg-brand-dark">{{type.editor_name ? type.editor_name : type.name}}</option>
        </select>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
export default {
  name: 'FilterGameTypes',
  props: {
    defaultGameTypeChain: Array,
  },
  data () {
    return {
      filter: {
        game_type_id: this.$route.query['game_type_id'] || '',
        //game_type_id: '',
      },
      gameTypes: {},
      categories: [],
      selectedKeys: [],
      typeId: '',
      subTypeId: '',
    }
  },
  mounted () {
    this.getGameTypes();
  },
  watch: {
    defaultGameTypeChain () {
      this.selectedKeys = this.defaultGameTypeChain
      if (this.categories.length) {
        this.readFromSelectedKeys()
      }
    },
    'filter.game_type_id': {
      handler (val) {
        this.addFilters(val)
      },
      deep: true,
    },
    typeId (val, oldval) {
      if (oldval !== '') this.filter.game_topic_id = ''
      this.filter.game_type_id = val
    },
    subTypeId (val, oldval) {
      if (oldval !== '') this.filter.game_topic_id = ''
      this.filter.game_type_id = val
    },
    selectedKeys () {
      this.$emit('filterChange', this.selectedKeys)
    },
    defaultType (val) {
      if (!this.filter.game_type_id) {
        this.filter.game_type_id = val
        this.getTypeIds()
      }
    },
  },
  methods: {
    readFromSelectedKeys () {
      while (this.categories.length > 1) {
        this.categories.pop()
      }
      this.selectedKeys.forEach((key, index) => {
        this.addCategory(index, key)
      })
    },
    addFilters (filter) {
      this.$emit('input', { query: filter, gameTypeChain: this.selectedKeys, lastCategory: this.categories[this.categories.length - 1] })
    },
    getGameTypes () {
      axios.get('/partners/game-types')
        .then((data) => {
          this.gameTypes = data.data.data
          // set default select distribution format if there is no game types chain
          if (this.$route.query['game_type_id']) {
            this.getGameChain();
          } else {
            this.setFirstDefaultKey(this.gameTypes)
          }
          // this.addFilters(this.filter);
        })
    },
    getGameChain () {
      let gameType = this.$route.query['game_type_id'];
      axios.get(`/partners/game-types/${gameType}/path`)
          .then((data) => {
            console.log('adddssdddrrrr', data.data)
            if (data.data[0]) {
              this.selectedKeys = data.data[0]
            }
            this.setFirstDefaultKey(this.gameTypes)
          })
    },
    getTypeIds () {
      if (this.filter.game_type_id) {
        Object.values(this.gameTypes).forEach((type) => {
          if (type.id === this.filter.game_type_id) {
            this.typeId = type.id
            this.subTypeId = ''
          }
          if (Object.keys(type.subType.data).length > 0) {
            Object.values(type.subType.data).forEach((subType) => {
              if (subType.id === this.filter.game_type_id) {
                this.typeId = subType.parent_id
                this.subTypeId = subType.id
              }
            })
          }
        })
      }
    },
    clear () {
      for (let key in this.filter) {
        if (key === 'game_type_id') {
          this.filter[key] = this.defaultType
          continue
        }
        this.filter[key] = ''
      }
      this.typeId = this.defaultType
      this.subTypeId = ''
    },
    setFirstDefaultKey (gameTypes) {
      // push gameTypes into categories
      this.categories.push(this.gameTypes)
      if (this.filter.game_type_id) {

      }
      if (this.selectedKeys.length === 0) {
        this.selectedKeys[0] = Object.keys(gameTypes)[0]
        this.categories.push(this.categories[0][this.selectedKeys[0]].subType.data)
      } else {
        this.readFromSelectedKeys()
      }
    },
    updateCategories (event, index) {
      const key = event.target.value

      while (this.selectedKeys.length > index) {
        this.selectedKeys.pop()
      }
      this.selectedKeys.push(key)

      // if user does not select anything we will remove filter
      if (key === 'noSelected') {
        this.categories.length = index + 1
        this.filter.game_type_id = ''
        this.selectedKeys.length = index
      }

      // add last game as game_type_id
      if (this.categories[index][key] && this.categories[index][key].subType.data.length === 0) {
        this.filter.game_type_id = this.categories[index][key].id
        this.addFilters(this.filter.game_type_id)
        return
      }

      // replace index in categories
      if (this.categories[index][key] && this.categories[index + 1]) {
        this.replaceCategory(index, key)
      } else if (this.categories[index][key]) {
        // push into categories
        this.addCategory(index, key)
      }
    },
    replaceCategory (index, key) {
      this.categories.length = index + 1
      this.categories.splice(index + 1, 1, this.categories[index][key].subType.data)
    },
    addCategory (index, key) {
      if (this.categories[index][key].subType.data && Object.keys(this.categories[index][key].subType.data).length) {
        this.categories.push(this.categories[index][key].subType.data)
      }
    },
    selectBoxPlaceholder (index) {
      switch (index) {
        case 0 :
          return 'Формат'
        case 1:
          return 'Игра'
        case 2:
          return 'Категория'
        default:
          return 'Тематика'
      }
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;
</style>
